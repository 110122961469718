<template>
  <b-container>

    <b-card title="Grupo">
      <UniqueTable
        :data="data.groups"
      />
    </b-card>
    <b-card title="Cedentes">
      <assignor-table
        :data="data.assignors.map(item => ({
          ...item,
          ...item.assignor
        }))"
      />
    </b-card>
  </b-container>
</template>

<script>
import {
    BContainer,
    BCard,
} from 'bootstrap-vue';

import AssignorTable from './Table.vue';
import UniqueTable from './UniqueTable.vue';

import AssignorService from '@/service/assignor/assignor';

export default {

    components: {
        AssignorTable,
        BContainer,
        BCard,
        UniqueTable,
    },
    data() {
        return {
            groupId: this.$route.params.id,
            data: {
                groups: [],
                assignors: [],
            },
        };
    },

    async mounted() {
        this.callLoading(true);
        const response = await AssignorService.getGroup(this.groupId);
        if (response.status === 404) {
            this.$router.push('/error-404');
            return;
        }
        this.data = response.data.data;
        this.callLoading(false);
    },
};

</script>
